import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import 'tailwindcss/tailwind.css';
import './index.css';
import dbzgroup from './../images/dbzgroup.jpeg';
export const _frontmatter = {
  "title": "Tarik's Dragonball,Z, and GT Legend",
  "path": "/"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img className="mx-auto" src={dbzgroup} />
    <p>{`Welcome!`}</p>
    <p>{`Hi my name is Tarik Hamilton. I mabey only 10 years old, but I'm smarter then you think! Please stay as long as you want. This site will load you with information. To find "information" on DragonBall Z click the link that says "information" and you get everything even news! Also not just info just about everything! So I hope you have fun.`}</p>
    <p>{`Last Updated: Under Contsrution through 1/02 and 3/02`}</p>
    <p>{`Hi, im back in bussiness. No one hardly comes here, but now I'm up-grading it. Now there's more coming each day!!!`}</p>
    <p>{`3/2/2002`}</p>
    <p>{`Now, I updated the Fan Art and alittle more!!!`}</p>
    <p>{`3/12/02`}</p>
    <p>{`Hi!!! I may not work on my site barely, but I'm making the effort of putting a Java chat on my channel. It's like mIRC, but you don't have to download it! Now you can talk to me and MysticSonic!!!`}</p>
    <p>{`3/16/02`}</p>
    <p>{`Beside making 2 more Java Chats in my new part of the site. I up-graded the Saga Summaries.`}</p>
    <p>{`4/6/2002`}</p>
    <p>{`Sorry guys... I got a new site. I partnered up with MysticSonic. The new site is `}<a parentName="p" {...{
        "href": "http://freewebz.com/dbzuu"
      }}>{`http://freewebz.com/dbzuu`}</a></p>
    <p><a parentName="p" {...{
        "href": "infoscreen.html"
      }}>{`information`}</a>{`
`}<a parentName="p" {...{
        "href": "javachatinstructions.html"
      }}>{`Java Chat`}</a>{`
`}<a parentName="p" {...{
        "href": "affiliates.html"
      }}>{`affiliates`}</a>{`
`}<a parentName="p" {...{
        "href": "javachatinstructions.html"
      }}>{`Poll`}</a>{`
`}<a parentName="p" {...{
        "href": "aboutmeIM000116.JPG.html"
      }}>{`About Me!`}</a>{`
`}<a parentName="p" {...{
        "href": "Tarikrm2ksite.html"
      }}>{`RPG Maker 2000 Section`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      